import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenerVehiculosSinTagSelect } from "../../redux/actions/vehiculo";
import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { agregarTag, editarTag } from "../../redux/actions/tagcamion";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  numero_tag: "",
  placa_vehiculo: "",
  id_vehiculo: "",
  estadoTag: "LIBRE",
};

const errorsInit = {
  ...initDataForm,
};

const ModalTagCamion = ({
  tagcamion,
  editar = false,

  numero_tag,
}) => {
  const [procesar, setProcesar] = useState(false);
  const [mensaje, setMensaje] = useState(null);
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaVehiculos } = state.vehiculo;
  const { addOk, editOk } = state.tagcamion;
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCloseReload = () => {
    handleClose();
  };

  useEffect(() => {
    dispatch(obtenerVehiculosSinTagSelect(numero_tag));
  }, []);

  useEffect(() => {
    if (editar) {
    
      setDataForm({ ...tagcamion });
    }
  }, [tagcamion,editar]);

  useEffect(() => {
    if (addOk) {
      history.push("/tagscamion");
    }
  }, [addOk]);
  useEffect(() => {
    if (editOk) {
      history.push("/tagscamion");
    }
  }, [editOk]);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    setErrors({ ...errorsInit });
  };

  const handleChange2 = (selectValue) => {
    const index = selectValue.target.selectedIndex;

    if (selectValue.target.value == -1) {
      setDataForm({
        ...dataForm,
        placa_vehiculo: "",
        id_vehiculo: "",
        estadoTag: "LIBRE",
      });
    } else {
      setDataForm({
        ...dataForm,
        placa_vehiculo: selectValue.target[index].label,
        id_vehiculo: selectValue.target.value,
        estadoTag: "ASIGNADO",
      });
    }
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key == "numero_tag") {
        if (dataForm[key].trim() === "" || dataForm[key].length === 0) {
          localErrors[key] = "Campo Requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isValid()) {
      if (editar) {
        console.log("ed", dataForm);
        dispatch(editarTag(dataForm));
      } else {
        console.log("agregar", dataForm);
        dispatch(agregarTag(dataForm));
      }
    }
  };

  return (
    <>
      <Button
        className="btn btn-sm text-white"
        style={{ backgroundColor: "#0A5189" }}
        onClick={handleShow}
      >
        {editar ? (
          <FormattedMessage id="Edit" />
        ) : (
          <FormattedMessage id="Add" />
        )}
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {editar ? (
              <FormattedMessage id="edit_tagvehicle" />
            ) : (
              <FormattedMessage id="add_tagvehicle" />
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={6}>
                <Form.Label className="mb-5" htmlFor="numero_tag">
                  <FormattedMessage id="tag_number" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  id="numero_tag"
                  name="numero_tag"
                  value={dataForm.numero_tag}
                  onChange={handleChange}
                />

                {errors.numero_tag && (
                  <span style={{ fontSize: "14px", color: "red" }}>
                    {errors.numero_tag}
                  </span>
                )}
              </Col>

              <Col xs={6}>
                <Form.Label className="mb-5" htmlFor="placa_vehiculo">
                  <FormattedMessage id="tag_vehicle" />
                </Form.Label>

                <Form.Select
                  name="id_vehiculo"
                  value={dataForm.id_vehiculo}
                  onChange={(selectValue) => handleChange2(selectValue)}
                >
                  {listaVehiculos.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="mb-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
            <FormattedMessage id="Cancel" />
          </Button>

          <Button
            size="sm"
            onClick={handleSubmit}
            style={{ backgroundColor: "#0A5189" }}
          >
            {editar ? (
              <FormattedMessage id="Edit" />
            ) : (
              <FormattedMessage id="Add" />
            )}
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalTagCamion;
