import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenerVehiculosSinTagSelect } from "../../redux/actions/vehiculo";
import moment from "moment";
import { Link } from "react-router-dom";
import VirtualizedSelect from "react-virtualized-select";
import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import { useHistory } from "react-router-dom";

import { agregarTag } from "../../redux/actions/tagcamion";

const initDataForm = {
  numero_tag: "",
  placa_vehiculo: "",
  id_vehiculo: "",
  estadoTag: "LIBRE",
};

const errorsInit = {
  ...initDataForm,
};

const NuevoTag = ({
  agregarNuevoTag,
  tagcamion,
  editar = false,
  editarTag,
  numero_tag,
}) => {
  const [procesar, setProcesar] = useState(false);
  const [mensaje, setMensaje] = useState(null);
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [placa_cabezal, setPlacacabezal] = useState("");
  const [estadoTag, setEstadoTag] = useState("LIBRE");
  const [fecha_asignado, setfechaAsignado] = useState("");
  const [hora_asignado, setHoraAsignado] = useState("");
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaVehiculos } = state.vehiculo;
  const { addOk } = state.tagcamion;
  const history = useHistory();

  useEffect(() => {
    dispatch(obtenerVehiculosSinTagSelect(numero_tag));

    // dispatch(obtenerCamionesFiltrados(tagcamion?.numero_tag));
  }, []);

  useEffect(() => {
    if (editar) {
      console.log("el camion", tagcamion);
      setDataForm({ ...tagcamion });
    }
  }, []);

  useEffect(() => {
    if (addOk) {
      history.push("/tagscamion");
    }
  }, [addOk]);

  const nuevoTag = (tag) => {
    dispatch(agregarTag(tag));
    // agregar producto provider
  };

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    if ([e.target.name] == "placa_vehiculo") {
      let index = e.nativeEvent.target.selectedIndex;
      if (e.target.value !== -1) {
        setDataForm({
          ...dataForm,
          [e.target.name]: e.target.value,
          placa_vehiculo: e.nativeEvent.target[index].label,

          estadoTag: "ASIGNADO",
        });
      } else {
        console.log("entra al -2");
        setDataForm({
          ...dataForm,
          [e.target.name]: e.target.value,
          placa_vehiculo: "",
        });

        setfechaAsignado();
        setHoraAsignado();
      }
    }
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key == "numero_tag") {
        if (dataForm[key].trim() === "" || dataForm[key].length === 0) {
          localErrors[key] = "campo requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleChange2 = (selectValue) => {
    if (selectValue.value == -1) {
      setDataForm({
        ...dataForm,
        placa_vehiculo: "",
        id_vehiculo: "",
        estadoTag: "LIBRE",
      });
    } else {
      setDataForm({
        ...dataForm,
        placa_vehiculo: selectValue.label,
        id_vehiculo: selectValue.value,
        estadoTag: "ASIGNADO",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isValid()) {
      if (editar) {
        editarTag(dataForm);
      } else {
        console.log("es vaido", isValid());
        nuevoTag(dataForm);
      }
      // limpiar los campos
      // setDataForm(initDataForm);
    }
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 ">
            <div
              className="card card-primary shadow-lg p-3 mb-5 bg-white rounded"
              style={{ padding: "0em" }}
            >
              <div className="card-header">
                {editar ? (
                  <h1 className="card-title">Editar TAG Camion</h1>
                ) : (
                  <h1 className="card-title">Agregar TAG Camion</h1>
                )}
              </div>
              <form onSubmit={handleSubmit} style={{ maxWidth: "50em" }}>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="numero_tag">Numero Tag</label>
                    <input
                      type="text"
                      className="form-control"
                      id="numero_tag"
                      name="numero_tag"
                      value={dataForm.numero_tag}
                      onChange={handleChange}
                    />
                    {errors.numero_tag && (
                      <span style={{ fontSize: "14px", color: "red" }}>
                        {errors.numero_tag}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="placa_vehiculo">Vehiculo</label>
                    <VirtualizedSelect
                      value={dataForm.id_vehiculo}
                      onChange={(selectValue) => handleChange2(selectValue)}
                      options={listaVehiculos?.map((item, index) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                    ></VirtualizedSelect>
                  </div>
                </div>

                <div className="card-footer">
                  <button type="submit" className="btn btn-primary">
                    {editar ? "Editar TAG" : "Agregar TAG"}{" "}
                    {procesar && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                  </button>
                  <Link className="btn btn-warning ml-2" to={`/tagscamion`}>
                    Cancelar
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NuevoTag;
